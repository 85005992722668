import React, { useEffect, useState } from 'react';
import { Row, Tabs, Tab, Container, Button, Image, Breadcrumb, Spinner, Alert, Col } from 'react-bootstrap';
import { BsPlus } from 'react-icons/bs';
import { Link, useParams } from 'react-router-dom';
import { collection, doc, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../../firebase';
import ViewModal from '../../../modal/viewModal';
import AddClassForm from '../../../Component/Forms/addClassForm';
import AddHubForm from '../../../Component/Forms/addHubForm';
import UpcomingClasses from './UpcomingClasses';
import ClosedClasses from './ClosedClasses';
import logo from "../../../assets/img/logo.png";
import { Body, HeaderBanner } from '../Dashboard/DashboardElement';
import { HeaderBannerIcon } from '../../Partners/Dashboard/PartnerDashboardElement';

const SingleCourse = () => {
    let params = useParams();
    const courseId = params.courseId;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [showClassModal, setShowClassModal] = useState(false);
    const [showHubModal, setShowHubModal] = useState(false);
    const [classes, setClasses] = useState([]);
    const [students, setStudents] = useState([]);
    const [selectedClass, setSelectedClass] = useState(null);
    const [location, setLocation] = useState({});
    const [key, setKey] = useState('class');

    const fetchClasses = async () => {
        setLoading(true);
        setError("");
        try {
            const q = query(collection(db, "SATclasses"));
            const querySnapshot = await getDocs(q);
            let classesData = [];
            querySnapshot.forEach((doc) => {
                classesData.push({ id: doc.id, ...doc.data() });
            });
            setClasses(classesData);
        } catch (error) {
            console.error("Error fetching classes: ", error);
            setError("Failed to fetch classes.");
        }
        setLoading(false);
    };

    const fetchStudents = async (classId) => {
        setLoading(true);
        setError("");
        try {
            const q = query(collection(db, "SATstudents"), where("classId", "==", classId));
            const querySnapshot = await getDocs(q);
            let studentsData = [];
            querySnapshot.forEach((doc) => {
                studentsData.push({ id: doc.id, ...doc.data() });
            });
            setStudents(studentsData);
        } catch (error) {
            console.error("Error fetching students: ", error);
            setError("Failed to fetch students.");
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchClasses();
    }, [courseId]);

    return (
        <Body>
            <Container className="m-2 mx-auto align-content-sm-center container-fluid">
                <Breadcrumb>
                    <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/dashboard" }}>Dashboard</Breadcrumb.Item>
                    <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/dashboard/courses" }}>Courses</Breadcrumb.Item>
                    <Breadcrumb.Item active>SAT</Breadcrumb.Item>
                </Breadcrumb>
                <HeaderBanner className="row mx-auto my-auto p-2">
                    <HeaderBannerIcon md={2} lg={2}>
                        <Image src={logo} rounded fluid />
                    </HeaderBannerIcon>
                    <Col md={6} lg={8}>
                        <h3>{location.name}</h3>
                        <p>{location.location}</p>
                    </Col>
                    <Col md={3} lg={2} className="text-end mx-auto my-auto">
                        <Button onClick={() => setShowClassModal(true)} className="my-auto btn my-auto mx-auto"><BsPlus /> Add Class</Button>
                    </Col>
                    <ViewModal
                        handleClose={() => setShowClassModal(false)}
                        show={showClassModal}
                        button="close"
                        title="Add Class">
                        <AddClassForm course={location} />
                    </ViewModal>
                    <Col md={3} lg={2} className="text-end mx-auto my-auto">
                        <Button onClick={() => setShowHubModal(true)} className="my-auto btn my-auto mx-auto"><BsPlus /> Add Hub</Button>
                    </Col>
                    <ViewModal
                        handleClose={() => setShowHubModal(false)}
                        show={showHubModal}
                        button="close"
                        title="Add Hub">
                        <AddHubForm course={location} />
                    </ViewModal>
                </HeaderBanner>
                <Row>
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="mb-5">
                        <Tab eventKey="class" title="Classes">
                            <UpcomingClasses classes={classes} />
                            <ClosedClasses courseId={courseId} />
                        </Tab>
                        <Tab eventKey="hub" title="Innovators Hubs">
                            {/* <HubSessions courseId={courseId} /> */}
                        </Tab>
                    </Tabs>
                </Row>
                {loading && <Spinner animation="grow" variant="success" />}
                {error && <Alert variant="danger">{error}</Alert>}
            </Container>
        </Body>
    );
};

export default SingleCourse;
