import React from 'react';
import {
  BrowserRouter as Router,Route,Routes,
  Link
} from "react-router-dom";
import { AuthProvider } from './Component/Context/AuthContext';
import Footer from './Component/Footer/Footer';
import Header from './Component/Header/Header';
// import PrivateRoute from './Component/Context/PrivateRoute'
import PublicRoute from './Component/Context/PublicRoute'
import Landing from './views/Landing/Landing'
import Sessions from './views/Landing/Sessions'
import Sessionstest from './views/Landing/Sessionstest';
import SignIn from './views/Admin/UserAuth/Login';
import PrivateRoute from './Component/Context/PrivateRoute';
import Dashboard from './views/Admin/Dashboard/Dashboard';
import Branches from './views/Admin/Branches/Branches';
import SingleBranch from './views/Admin/Branches/SingleBranch';
import Classes from './views/Admin/Classes/Classes';
import AllStudentsDetails from './views/Admin/Students/AllStudentDetails';
import Hublogin from './views/Landing/hubLogin';
import { HubContextProvider } from './Component/Context/HubContext';
import HubDasboard from './views/Landing/hubDasboard';
import HubPrivateRoute from './Component/Context/HubPrivateRoute';
import HubPublicRoute from './Component/Context/HubPublicRoute';
import PartnerPrivateRoute from './Component/Context/PartnerPrivateRoute';
import PartnerPublicRoute from './Component/Context/PartnerPublicRoute';
import Hub from './views/Landing/Hub';
import Hubs from './views/Admin/Hub/Hubs';
import { PartnerContextProvider } from './Component/Context/PartnerContext';
import Partnerlogin from './views/Partners/PartnerLogin';
import PartnerDashboard from './views/Partners/Dashboard/PartnerDashboard';
import PartnerSingleBranch from './views/Partners/Branches/PartnerSingleBranch';
import PartnerClasses from './views/Partners/Classes/PartnerClasses';
import SingleCourse from './views/Admin/Courses/SingleCourse';
import Courses from './views/Admin/Courses/Courses';
import VirtualClasses from './views/Admin/Classes/VirtualClasses';
import SatBanner from './views/Landing/SatBanner';
import SessionsCoding from './views/Landing/SessionsCoding';
import SAT from './views/Admin/Courses/SAT';
import SATClassManager from './views/Admin/Courses/SATClassManager';

function App() {
  return (
  
      <Router>
      <AuthProvider>
        <HubContextProvider>
          <PartnerContextProvider>
      <Header />
        <Routes>
            <Route  path="/" element={<Landing/>} />
            <Route  path="/enroll" element={<Sessions/>} />
            <Route  path="/register" element={<Sessions/>} />
            <Route  path="/enroll-sat" element={<SatBanner/>} />
            <Route  path="/enroll-virtual-coding" element={<SessionsCoding/>} />
            
              <Route  path="/hub" element={<HubPublicRoute restricted><Hublogin/></HubPublicRoute>} />
              <Route  path="/join-hub" element={<HubPublicRoute><Hub/></HubPublicRoute>} />
              <Route  path="/hub-dashboard" element={<HubPrivateRoute><HubDasboard/></HubPrivateRoute>} />

            
            {/* <Route  path="/test" element={<Sessionstest/>} />  */}
            <Route  path="*" element={<div className='text-danger text-center'>PAGE NOT FOUND!</div>} /> 
            <Route  path="/login" element={<PublicRoute restricted><SignIn/></PublicRoute>} />
            <Route  path="/dashboard" element={<PrivateRoute><Dashboard/></PrivateRoute>} />
              
              
              <Route path="/dashboard/branches" element={<PrivateRoute ><Branches/> </PrivateRoute>}/>
              <Route path="/dashboard/courses" element={<PrivateRoute ><Courses/> </PrivateRoute>}/>
              <Route path="/dashboard/sat" element={<PrivateRoute ><SAT/> </PrivateRoute>}/>
              <Route path="/dashboard/emails" element={<PrivateRoute ><AllStudentsDetails/> </PrivateRoute>}/>
              <Route path="/dashboard/branches/:locationId" element={<PrivateRoute > <SingleBranch/> </PrivateRoute>}/>
              <Route path="/dashboard/branches/:locationId/:classId" element={<PrivateRoute > <Classes/> </PrivateRoute>}/>
              <Route path="/dashboard/courses/:courseId" element={<PrivateRoute > <SingleCourse/> </PrivateRoute>}/>
              <Route path="/dashboard/courses/:courseId/:classId" element={<PrivateRoute > <VirtualClasses/> </PrivateRoute>}/>
              <Route path="/dashboard/branches/:locationId/hub/:hubId" element={<PrivateRoute > <Hubs/> </PrivateRoute>}/>
              


              <Route  path="/partners" element={<PartnerPublicRoute restricted><Partnerlogin/></PartnerPublicRoute>} />
              <Route  path="/partners/dashboard" element={<PartnerPrivateRoute restricted><PartnerDashboard/></PartnerPrivateRoute>} />
              <Route path="/partners/dashboard/:locationId" element={<PartnerPrivateRoute > <PartnerSingleBranch/> </PartnerPrivateRoute>}/>
              <Route path="/partners/dashboard/:locationId/:classId" element={<PartnerPrivateRoute > <PartnerClasses/> </PartnerPrivateRoute>}/>
              <Route path="/partners/dashboard/:locationId/hub/:hubId" element={<PartnerPrivateRoute > <Hubs/> </PartnerPrivateRoute>}/>

         
          

            {/* AUTHENTICATION ROUTES */}
            {/* <PublicRoute restricted  path="/signup" component={SignUp} />
            {/* USER ROUTES */} 
                      {/* <PublicRoute restricted path="/login" component={<SignIn/>} />  */}



        </Routes>
        <Footer/>
        </PartnerContextProvider>
        </HubContextProvider>
      </AuthProvider>
      </Router>
  );
}

export default App;
