import React, { useEffect, useState } from 'react';

import { Row,Nav,NavDropdown,Navbar ,Spinner, Col, Container, Button, Card , Table,Alert,Form,InputGroup,ProgressBar,Divider} from 'react-bootstrap';

// import { useAuth } from '../../Context/AuthContext';
// material ui imports

 import style from 'bootstrap/dist/css/bootstrap.min.css';
 import {PaystackButton} from "react-paystack";
import { getDownloadURL, getStorage, ref, uploadBytes, uploadBytesResumable } from '@firebase/storage';
import AddUser from '../Hooks/useAddUser';
import { FaLastfmSquare } from 'react-icons/fa';
import AddSATUser from '../Hooks/useAddSATUser';
import PaymentHub from '../Helpers/PaymentHub';
// import { DropzoneArea } from 'material-ui-dropzone';

//visible header that contains LOGO and Navigation Icon
var d = new Date();
var n = d.getTime();

const AddSATUserForm =({closeModal,type,location})=>{
     // files to upload
  const [data, setData] = useState({});
  const [mainError, setMainError] = useState('');
  const [img, setImg] = useState();
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(''); // sets Error from operations
  const [formError, setFormError] = useState([]); // check error from forms
  const [success, setSuccess] = useState(false);
  const [paid, setPaid] = useState(false);
  const [checked,setChecked] = useState(false)
  // const { userID } = useAuth();
  var price =  2000;
   

  const UpdateData = (item, value) => {
    setData((data) => ({ ...data, [item]: value }));
    setFormError((formError) => ({ ...formError, [item]: "" }));
   
  };

  const checkEventHandler = (event) => {
    let nam = event.target.name;

     setChecked(!checked)
     let val = !checked
    UpdateData(nam, val);
  };
  const eventHandler = (event) => {
    let val = event.target.value;
    let nam = event.target.name;
    UpdateData(nam, val);
  };

  
  function getAge(birthYear){
    var currentDate = new Date();
    var dob = new Date(birthYear).getFullYear();
      var currentYear = currentDate.getFullYear();
     var age = currentYear - dob;
      console.log(age);
      return age;

  }
  getAge(data.dob);

  const findFormErrors = () => {
    const newErrors = {};
    // name errors
    if (!data.firstname || data.firstname === "") newErrors.firstname = "Cannot be blank!";
    else if (data.firstname.length > 100) newErrors.firstname = "Name is too long!";
    // name errors
    if (!data.lastname || data.lastname === "") newErrors.lastname = "Cannot be blank!";
    else if (data.lastname.length > 100) newErrors.lastname = "Name is too long!";
    // name errors
    if (!data.pname || data.pname === "") newErrors.pname = "Cannot be blank!";
    else if (data.pname.length > 100) newErrors.pname = "Name is too long!";
    // name errors
    if (!data.dob || data.dob === "") newErrors.dob = "Cannot be blank!";
    // name errors
    if (!data.email || data.email === "") newErrors.pemail = "Add a valid email!";
    else if (data.email.length > 100) newErrors.pemail = "Name is too long!";
    if (!data.pemail || data.pemail === "") data.pemail =data.email;
    // food errors
    if ( !data.pcontact || data.pcontact === '' ) newErrors.pcontact = 'Add a valid Contact!';
    if ( !data.nationality || data.nationality === '' ) newErrors.nationality = 'Choose your nationality!';
    if ( !data.session || data.session === '' ) newErrors.session = 'Choose your session!';

    return newErrors;
  };

  const Submit = async (reference) => {
    // Add SAT STUDENT
    const { error, success } = await AddSATUser(data, type,reference);

    setMainError(error);
    setSuccess(success);
    // setData({ name: "", email: "", session:type });
    setLoading(false);
    // closeModal()
  };

  const Proceed = async () => {
    // event.preventDefault();
    setLoading(true);

    const newErrors = findFormErrors();
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setFormError(newErrors);
      setLoading(false);
      setValidated(false)

    } else {
      // No errors! Put any logic here for the form submission!
      setLoading(true);
      setValidated(true)
    }
  };



  const output = (
    <div className={style}>
    <Form noValidate validated={validated} onSubmit={Proceed} inline>

      <h3>Student Information</h3>
        <hr/>
        {/*user details */}
        <Form.Group className="row" controlId="validationCustom01">
          <Form.Label className="col-sm-12 col-md-3  align-bottom text-md-end mx-auto my-auto">
            First Name
          </Form.Label>
          <InputGroup className="form-input col">
            <Form.Control
              className=""
              type="text"
              name="firstname"
              required={true}
              value={data.firstname}
              onChange={eventHandler}
              isInvalid={!!formError.firstname}
            />
            <Form.Control.Feedback type="invalid">
              {formError.firstname}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group className="row" controlId="validationCustom01">
          <Form.Label className="col-sm-12 col-md-3  align-bottom text-md-end mx-auto my-auto">
            Last Name
          </Form.Label>
          <InputGroup className="form-input col">
            <Form.Control
              className=""
              type="text"
              name="lastname"
              required={true}
              value={data.lastname}
              onChange={eventHandler}
              isInvalid={!!formError.lastname}
            />
            <Form.Control.Feedback type="invalid">
              {formError.lastname}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group className="row" controlId="validationCustom01">
          <Form.Label className="col-sm-12 col-md-3 align-bottom text-md-end mx-auto my-auto">
            Other Names
          </Form.Label>
          <InputGroup className="form-input col">
            <Form.Control
              className=""
              type="text"
              name="othername"
              required={true}
              value={data.othername}
              onChange={eventHandler}
              isInvalid={!!formError.othername}
            />
            <Form.Control.Feedback type="invalid">
              {formError.othername}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group className="row" controlId="validationCustom01">
          <Form.Label className="col-sm-12 col-md-3 align-bottom text-md-end mx-auto my-auto">
            Date of Birth
          </Form.Label>
          <InputGroup className="form-input col">
            <Form.Control
              className=""
              type="date"
              name="dob"
              required={true}
              value={data.dob}
              onChange={eventHandler}
              isInvalid={!!formError.dob}
            />
            <Form.Control.Feedback type="invalid">
              {formError.dob}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <Form.Group className="row" controlId="validationCustom01">
          <Form.Label className="col-sm-12 col-md-3  align-bottom text-md-end mx-auto my-auto">
            E-mail
          </Form.Label>
          <InputGroup className="form-input col">
            <Form.Control
              className=""
              type="email"
              name="email"
              required={true}
              value={data.email}
              onChange={eventHandler}
              isInvalid={!!formError.email}
            />
            <Form.Control.Feedback type="invalid">
              {formError.email}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <Form.Group className="row" controlId="validationCustom01">
          <Form.Label className="col-sm-12 col-md-3  align-bottom text-md-end mx-auto my-auto">
            Contact (Phone Number)
          </Form.Label>
          <InputGroup className="form-input col">
            <Form.Control
              className=""
              type="text"
              name="contact"
              required={true}
              value={data.contact}
              onChange={eventHandler}
              isInvalid={!!formError.contact}
            />
            <Form.Control.Feedback type="invalid">
              {formError.contact}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
       

        <Form.Group className="row" controlId="validationCustom01">
          <Form.Label className="col-sm-12 col-md-3  align-bottom text-md-end mx-auto my-auto">
            Nationality 
          </Form.Label>
          
          <InputGroup className="form-input col">
            <Form.Select aria-label="Default select example"
             className=""
             type="text"
             name="nationality"
             required={true}
             value={data.nationality}
             onChange={eventHandler}
             isInvalid={!!formError.nationality}>
          <option value="">-- Select Nationality --</option>
              <option value="afghan">Afghan</option>
              <option value="albanian">Albanian</option>
              <option value="algerian">Algerian</option>
              <option value="american">American</option>
              <option value="andorran">Andorran</option>
              <option value="angolan">Angolan</option>
              <option value="antiguans">Antiguans</option>
              <option value="argentinean">Argentinean</option>
              <option value="armenian">Armenian</option>
              <option value="australian">Australian</option>
              <option value="austrian">Austrian</option>
              <option value="azerbaijani">Azerbaijani</option>
              <option value="bahamian">Bahamian</option>
              <option value="bahraini">Bahraini</option>
              <option value="bangladeshi">Bangladeshi</option>
              <option value="barbadian">Barbadian</option>
              <option value="barbudans">Barbudans</option>
              <option value="batswana">Batswana</option>
              <option value="belarusian">Belarusian</option>
              <option value="belgian">Belgian</option>
              <option value="belizean">Belizean</option>
              <option value="beninese">Beninese</option>
              <option value="bhutanese">Bhutanese</option>
              <option value="bolivian">Bolivian</option>
              <option value="bosnian">Bosnian</option>
              <option value="brazilian">Brazilian</option>
              <option value="british">British</option>
              <option value="bruneian">Bruneian</option>
              <option value="bulgarian">Bulgarian</option>
              <option value="burkinabe">Burkinabe</option>
              <option value="burmese">Burmese</option>
              <option value="burundian">Burundian</option>
              <option value="cambodian">Cambodian</option>
              <option value="cameroonian">Cameroonian</option>
              <option value="canadian">Canadian</option>
              <option value="cape verdean">Cape Verdean</option>
              <option value="central african">Central African</option>
              <option value="chadian">Chadian</option>
              <option value="chilean">Chilean</option>
              <option value="chinese">Chinese</option>
              <option value="colombian">Colombian</option>
              <option value="comoran">Comoran</option>
              <option value="congolese">Congolese</option>
              <option value="costa rican">Costa Rican</option>
              <option value="croatian">Croatian</option>
              <option value="cuban">Cuban</option>
              <option value="cypriot">Cypriot</option>
              <option value="czech">Czech</option>
              <option value="danish">Danish</option>
              <option value="djibouti">Djibouti</option>
              <option value="dominican">Dominican</option>
              <option value="dutch">Dutch</option>
              <option value="east timorese">East Timorese</option>
              <option value="ecuadorean">Ecuadorean</option>
              <option value="egyptian">Egyptian</option>
              <option value="emirian">Emirian</option>
              <option value="equatorial guinean">Equatorial Guinean</option>
              <option value="eritrean">Eritrean</option>
              <option value="estonian">Estonian</option>
              <option value="ethiopian">Ethiopian</option>
              <option value="fijian">Fijian</option>
              <option value="filipino">Filipino</option>
              <option value="finnish">Finnish</option>
              <option value="french">French</option>
              <option value="gabonese">Gabonese</option>
              <option value="gambian">Gambian</option>
              <option value="georgian">Georgian</option>
              <option value="german">German</option>
              <option value="ghanaian">Ghanaian</option>
              <option value="greek">Greek</option>
              <option value="grenadian">Grenadian</option>
              <option value="guatemalan">Guatemalan</option>
              <option value="guinea-bissauan">Guinea-Bissauan</option>
              <option value="guinean">Guinean</option>
              <option value="guyanese">Guyanese</option>
              <option value="haitian">Haitian</option>
              <option value="herzegovinian">Herzegovinian</option>
              <option value="honduran">Honduran</option>
              <option value="hungarian">Hungarian</option>
              <option value="icelander">Icelander</option>
              <option value="indian">Indian</option>
              <option value="indonesian">Indonesian</option>
              <option value="iranian">Iranian</option>
              <option value="iraqi">Iraqi</option>
              <option value="irish">Irish</option>
              <option value="israeli">Israeli</option>
              <option value="italian">Italian</option>
              <option value="ivorian">Ivorian</option>
              <option value="jamaican">Jamaican</option>
              <option value="japanese">Japanese</option>
              <option value="jordanian">Jordanian</option>
              <option value="kazakhstani">Kazakhstani</option>
              <option value="kenyan">Kenyan</option>
              <option value="kittian and nevisian">Kittian and Nevisian</option>
              <option value="kuwaiti">Kuwaiti</option>
              <option value="kyrgyz">Kyrgyz</option>
              <option value="laotian">Laotian</option>
              <option value="latvian">Latvian</option>
              <option value="lebanese">Lebanese</option>
              <option value="liberian">Liberian</option>
              <option value="libyan">Libyan</option>
              <option value="liechtensteiner">Liechtensteiner</option>
              <option value="lithuanian">Lithuanian</option>
              <option value="luxembourger">Luxembourger</option>
              <option value="macedonian">Macedonian</option>
              <option value="malagasy">Malagasy</option>
              <option value="malawian">Malawian</option>
              <option value="malaysian">Malaysian</option>
              <option value="maldivan">Maldivan</option>
              <option value="malian">Malian</option>
              <option value="maltese">Maltese</option>
              <option value="marshallese">Marshallese</option>
              <option value="mauritanian">Mauritanian</option>
              <option value="mauritian">Mauritian</option>
              <option value="mexican">Mexican</option>
              <option value="micronesian">Micronesian</option>
              <option value="moldovan">Moldovan</option>
              <option value="monacan">Monacan</option>
              <option value="mongolian">Mongolian</option>
              <option value="moroccan">Moroccan</option>
              <option value="mosotho">Mosotho</option>
              <option value="motswana">Motswana</option>
              <option value="mozambican">Mozambican</option>
              <option value="namibian">Namibian</option>
              <option value="nauruan">Nauruan</option>
              <option value="nepalese">Nepalese</option>
              <option value="new zealander">New Zealander</option>
              <option value="ni-vanuatu">Ni-Vanuatu</option>
              <option value="nicaraguan">Nicaraguan</option>
              <option value="nigerien">Nigerien</option>
              <option value="north korean">North Korean</option>
              <option value="northern irish">Northern Irish</option>
              <option value="norwegian">Norwegian</option>
              <option value="omani">Omani</option>
              <option value="pakistani">Pakistani</option>
              <option value="palauan">Palauan</option>
              <option value="panamanian">Panamanian</option>
              <option value="papua new guinean">Papua New Guinean</option>
              <option value="paraguayan">Paraguayan</option>
              <option value="peruvian">Peruvian</option>
              <option value="polish">Polish</option>
              <option value="portuguese">Portuguese</option>
              <option value="qatari">Qatari</option>
              <option value="romanian">Romanian</option>
              <option value="russian">Russian</option>
              <option value="rwandan">Rwandan</option>
              <option value="saint lucian">Saint Lucian</option>
              <option value="salvadoran">Salvadoran</option>
              <option value="samoan">Samoan</option>
              <option value="san marinese">San Marinese</option>
              <option value="sao tomean">Sao Tomean</option>
              <option value="saudi">Saudi</option>
              <option value="scottish">Scottish</option>
              <option value="senegalese">Senegalese</option>
              <option value="serbian">Serbian</option>
              <option value="seychellois">Seychellois</option>
              <option value="sierra leonean">Sierra Leonean</option>
              <option value="singaporean">Singaporean</option>
              <option value="slovakian">Slovakian</option>
              <option value="slovenian">Slovenian</option>
              <option value="solomon islander">Solomon Islander</option>
              <option value="somali">Somali</option>
              <option value="south african">South African</option>
              <option value="south korean">South Korean</option>
              <option value="spanish">Spanish</option>
              <option value="sri lankan">Sri Lankan</option>
              <option value="sudanese">Sudanese</option>
              <option value="surinamer">Surinamer</option>
              <option value="swazi">Swazi</option>
              <option value="swedish">Swedish</option>
              <option value="swiss">Swiss</option>
              <option value="syrian">Syrian</option>
              <option value="taiwanese">Taiwanese</option>
              <option value="tajik">Tajik</option>
              <option value="tanzanian">Tanzanian</option>
              <option value="thai">Thai</option>
              <option value="togolese">Togolese</option>
              <option value="tongan">Tongan</option>
              <option value="trinidadian or tobagonian">Trinidadian or Tobagonian</option>
              <option value="tunisian">Tunisian</option>
              <option value="turkish">Turkish</option>
              <option value="tuvaluan">Tuvaluan</option>
              <option value="ugandan">Ugandan</option>
              <option value="ukrainian">Ukrainian</option>
              <option value="uruguayan">Uruguayan</option>
              <option value="uzbekistani">Uzbekistani</option>
              <option value="venezuelan">Venezuelan</option>
              <option value="vietnamese">Vietnamese</option>
              <option value="welsh">Welsh</option>
              <option value="yemenite">Yemenite</option>
              <option value="zambian">Zambian</option>
              <option value="zimbabwean">Zimbabwean</option>
          </Form.Select>
            {/* <Form.Control
              className=""
              type="text"
              name="school"
              required={true}
              value={data.school}
              onChange={eventHandler}
              isInvalid={!!formError.school}
            /> */}
            <Form.Control.Feedback type="invalid">
              {formError.nationality}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group className="row" controlId="validationCustom01">
          <Form.Label className="col-sm-12 col-md-3  align-bottom text-md-end mx-auto my-auto">
            Prefered Class Session 
          </Form.Label>
          
          <InputGroup className="form-input col">
            <Form.Select aria-label="Default select example"
             className=""
             type="text"
             name="session"
             required={true}
             value={data.session}
             onChange={eventHandler}
             isInvalid={!!formError.session}>
          <option value="">-- Select preferred class --</option>
              <option value="weekdays">Weekday</option>
              <option value="weekends">Weekend</option>
             
          </Form.Select>
            {/* <Form.Control
              className=""
              type="text"
              name="school"
              required={true}
              value={data.school}
              onChange={eventHandler}
              isInvalid={!!formError.school}
            /> */}
            <Form.Control.Feedback type="invalid">
              {formError.session}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
     
        <hr/>
        <h3>Guardian Information</h3>
        <hr/>
        <Form.Group className="row" controlId="validationCustom01">
          <Form.Label className="col-sm-12 col-md-3  align-bottom text-md-end mx-auto my-auto">
          Parents Full Name
          </Form.Label>
          <InputGroup className="form-input col">
            <Form.Control
              className=""
              type="text"
              name="pname"
              required={true}
              value={data.pname}
              onChange={eventHandler}
              isInvalid={!!formError.pname}
            />
            <Form.Control.Feedback type="invalid">
              {formError.pname}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
   
      
        <Form.Group className="row" controlId="validationCustom01">
          <Form.Label className="col-sm-12 col-md-3  align-bottom text-md-end mx-auto my-auto">
            Parents Contact
          </Form.Label>
          <InputGroup className="form-input col">
            <Form.Control
              className=""
              type="text"
              name="pcontact"
              required={true}
              value={data.pcontact}
              onChange={eventHandler}
              isInvalid={!!formError.pcontact}
            />
            <Form.Control.Feedback type="invalid">
              {formError.pcontact}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        
       
     
   


       
        
        
        <Col className="text-center">
                    <Button
                    onClick={()=>Proceed()}
                    className=" btn-primary text-center"
                    >
                    Proceed {(loading && !error)   &&  <Spinner animation="border" variant="white"/> }
                    </Button>

                </Col>
                </Form>

    </div>
    )

    return(
        <>
        {(error && !loading  ) && <Alert variant="danger"><h3 className="text-muted mx-auto">{error}</h3></Alert>}
            
            {(success && !loading ) && <Alert variant="success">
            <h3 className="mx-auto">{"Registration Succesfuly"}</h3>
              <span className="mx-auto">Kindly check your mail "{data.email}" for confirmation and reciepts, Thank you! </span>
            </Alert> }
            {(!error && !success && !validated && !loading )   &&  output }
            {
            (paid && !success )   &&  <div className="mx-auto text-center" >
              <Spinner className="mx-auto" animation="border" variant="success"/> 
              
              </div>

        }
            {
            (loading && !success )   &&  <div className="mx-auto text-center" >
              {/* <Spinner className="mx-auto" animation="border" variant="info"/>  */}
              
              </div>

        }
            {(!error && !success && !paid && validated)   &&  <Col className="text-center">
              <h3>
                You are almost there...
              </h3>
             
                <PaymentHub amount={price} data={data}  Submit={(reference)=>Submit(reference)}/>
        </Col> }

            
        </>
     
    );
}

export default AddSATUserForm;